.App {
  text-align: center;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333; /* Base text color */
  background-color: #f8f8f8; /* Light background color */

}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.content {
  display: flex; /* Flexbox layout */
  flex-direction: column; /* Row layout by default (mobile-first) */
  width: 80%;
  max-width: 1200px; /* Maximum width for larger screens */
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Padding around the container */
  gap: 20px; /* Gap between items */
}

/* Responsive Design for Larger Screens */


@media (min-width: 1200px) {
  .content {
    flex-direction: column; /* Column layout for larger screens */
  }

}

@media (min-width: 768px) {
  .content {
    flex-direction: row; /* row layout for smaller screens */
  }
}

.column {
  padding: 20px 0; /* Padding for main content */
  flex: auto; /* Allow items to grow and shrink */
}


ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  background: #fff;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}


/* Headings */
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 10px; /* Margin below headings */
}

h1 {
  font-size: 1.2rem; /* Responsive font size for headings */
}

h2 {
  font-size: 1.0rem;
}

h3 {
  font-size: 0.8rem;
}

/* Links */
a {
  color: #007bff; /* Link color */
  text-decoration: none; /* Remove underline */
}

a:hover {
  text-decoration: underline; /* Underline on hover */
}